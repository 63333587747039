import { BalancesState } from '@/app/types/store/balances';
import { DictionariesState } from '@/app/types/store/dictionaries';
import { OrganizationsState } from '@/app/types/store/organizations';
import { ServiceState } from '@/app/types/store/service';
import { UsersState } from '@/app/types/store/users';
import { VerificationState } from '@/app/types/store/verification';

export interface RootState {
  users: UsersState;
  balances: BalancesState;
  dictionaries: DictionariesState;
  verification: VerificationState;
  service: ServiceState;
  organizations: OrganizationsState;
}

export enum StoreAction {
  UpdateVerification = 'verification/updateVerification',
  // organizations
  SetActiveAccount = 'organizations/setActiveAccount',
  UpdateActiveAccount = 'organizations/updateActiveAccount',
  // balances
  CreateBalancesMap = 'balances/createBalancesMap',
  UpdateCryptoBalancesMap = 'balances/updateCryptoBalancesMap',
  UpdateFiatBalancesMap = 'balances/updateFiatBalancesMap',
  UpdateCryptoBalancesMapManual = 'balances/updateCryptoBalancesMapManual',
}

export enum StoreCommit {
  ChangeServerStatus = 'service/changeServerStatus',
  // users
  SetUser = 'users/setUser',
  // dictionaries
  SetCurrencies = 'dictionaries/setCurrencies',
  SetCryptoCurrencies = 'dictionaries/setCryptoCurrencies',
  SetExchangeRates = 'dictionaries/setExchangeRates',
  SetNetworkFees = 'dictionaries/setNetworkFees',
  // organizations
  SetOrganizations = 'organizations/setOrganizations',
  SetCurrentOrganization = 'organizations/setCurrentOrganization',
  SetOrgAccounts = 'organizations/setOrgAccounts',
  SetAllowedCryptoCurrencies = 'organizations/setAllowedCryptoCurrencies',
}
