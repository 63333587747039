export default {
  twoStepVerification: {
    title: 'Two-step verification',
    description: "Enter the authentication code we've sent to",
    below: 'below',
    resendCode: 'Resend code',
    requestCode: 'Request another code in ',
    checkEmailMessage: 'Confirmation code was sent to your email',
  },
  successApiKey: {
    alertTitle: 'Attention! Copy and save your API key',
    alertDescription:
      "As soon as this box is closed you loose access to it — you'll have to generate a new key to recover access to your API.",
    actionButton: 'Copy API Key',
  },
  // payments
  successSinglePayment: {
    title: 'Payment confirmed!',
    description: [
      'Your one-time payment has been confirmed. \n You can *track its progress* on the \n',
      'Transaction history',
      'page.',
    ],
    descriptionExternal: [
      'Withdrawal request has been successfully made. \n All request details can be found on the \n',
      'Requests history',
      'page.',
    ],
    actionButton: 'Make another payment!',
  },
  // payouts
  payoutCreation: {
    title: 'Adding new mass payout',
    renamePayout: 'Rename payout',
    formatTitle: 'CSV file',
    payoutName: 'Payout name',
    payoutCreated: 'Mass payout created successfully',
    payoutUpdated: 'Mass payout updated successfully',
    payoutCurrency: 'Payout currency',
  },
  deletePayout: {
    title: 'Are you sure?',
    reallyWantDelete: 'Do you really want to delete payout *"{payoutName}"*?',
    payoutDeleted: 'Payout has been deleted',
  },
  successPayoutPayment: {
    title: 'Payout is being processed',
    description: 'You can see the payout status on the *“Mass payouts”* page.',
  },
  // withdraw
  successWithdraw: {
    title: 'Payment confirmed!',
    description: [
      'Withdrawal request has been successfully made. \n All request details can be found on the \n',
      'Requests history',
      'page.',
    ],
    actionButton: 'Make another payment',
  },
  withdrawFiatAccounts: {
    title: 'Linked bank accounts',
    description: 'Click to select',
    placeholder:
      'Before you can add or withdraw fiat from your account, you need to first link your bank account.',
  },
  withdrawFiatConfirm: {
    title: 'Confirmation',
    fields: {
      transferMethod: 'Transaction method:',
      bankName: 'Bank name:',
      accountNum: 'Account Nr.:',
      amount: 'Amount:',
      serviceFee: 'Service fee:',
      totalReceive: 'You will receive: ',
    },
    note: `The funds will be sent within 1-3 business days.
    Your bank may impose fees on their end.`,
  },
  transferFiatSuccess: {
    title: 'Transfer Successful!',
    description:
      'Your funds have been successfully transferred to your second account.',
    actionButton: 'Make another transfer',
  },
  // exchange
  exchangePreview: {
    title: 'Confirmation',
  },
  exchangeSuccess: {
    title: 'Exchange Request Placed',
    description:
      'Your exchange request has been successfully placed. You can make another exchange request or view your ',
    link: 'existing requests.',
    actionButton: 'Start another exchange',
  },
  // funding
  createFunding: {
    title: 'Create new funding Instruction',
  },
  fundingSuccess: {
    title: 'Funding Instruction created!',
    description: 'You can download the document now or find it later in the ',
    link: 'instructions section',
  },
  fundingFail: {
    title: 'Duplicate Funding Instruction Detected',
    descriptionOne:
      'You already have an active Funding Instruction for this bank with the same amount.',
    descriptionTwo: 'All previously created documents can be found in the ',
    descriptionTwoEnd: ' section',
    link: 'Funding Instructions Archive',
  },
};
