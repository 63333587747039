import { HttpStatus } from '@/app/types/api/config/config';
import { RouteName } from '@/app/types/app/routes';

import Store from '@/store';

import ClientResponseError from '@/app/errors/ClientResponseError';
import SimpleClientError from '@/app/errors/SimpleClientError';

import router from '@/router';

export function resolveRoute(name: RouteName) {
  return router.resolve({ name });
}

export function resolveVerifiedUserRoute(toRouteName: RouteName) {
  const toRouteMaintenance = toRouteName === RouteName.Maintenance;
  const accessAllowed =
    toRouteName === RouteName.AccessRestricted &&
    !Store.getters['users/isUserBlocked'];

  if (toRouteMaintenance || accessAllowed) {
    return resolveRoute(RouteName.Dashboard);
  }

  return;
}

export function resolveServicePageRoute(
  error: Error | ClientResponseError | SimpleClientError,
  toRouteName: RouteName,
) {
  if (error instanceof ClientResponseError) {
    switch (error.status) {
      case HttpStatus.ServiceUnavailable:
        if (toRouteName === RouteName.Maintenance) {
          return;
        }

        return resolveRoute(RouteName.Maintenance);
      case HttpStatus.Forbidden:
        if (toRouteName === RouteName.AccessRestricted) {
          return;
        }

        return resolveRoute(RouteName.AccessRestricted);
      default:
        return;
    }
  }

  return;
}
