import { ExchangeRateCurrencyPair } from '@/app/types/app/currency';
import {
  DictionariesGetters,
  DictionariesMutations,
  DictionariesState,
} from '@/app/types/store/dictionaries';

const state = (): DictionariesState => ({
  currencies: [],
  cryptocurrencies: [],
  exchangeRates: [],
  networkFees: [],
});

const mutations: DictionariesMutations = {
  setCurrencies(state, currencies) {
    state.currencies = currencies;
  },

  setCryptoCurrencies(state, cryptocurrencies) {
    state.cryptocurrencies = cryptocurrencies;
  },

  setExchangeRates(state, exchangeRates) {
    state.exchangeRates = exchangeRates;
  },

  setNetworkFees(state, networkFees) {
    state.networkFees = networkFees;
  },
};

const getters: DictionariesGetters = {
  getExchangeRatesMap: (state) => {
    const rates: Partial<Record<ExchangeRateCurrencyPair, number>> = {};

    state.exchangeRates.forEach(
      (rate) => (rates[rate.currency_pair] = rate.price),
    );

    return rates;
  },
};

export default {
  namespaced: true,

  state,
  mutations,
  getters,
};
