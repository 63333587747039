import { AxiosRequestHeaders } from 'axios';

import { BackendResponse } from '@/app/types/api/config/config';
import { DictionaryResponse } from '@/app/types/api/resources/dictionaries';
import {
  User,
  UserChangePassword,
  UsersApi,
  UsersMeResponse,
} from '@/app/types/api/resources/users';

import client from '@/app/api/client';

export default {
  fetchMe(): BackendResponse<UsersMeResponse> {
    return client.get(UsersApi.Me);
  },

  validatePasswordChange(
    data: UserChangePassword,
  ): Promise<DictionaryResponse<[]>> {
    return client.put(UsersApi.ValidatePassChange, data);
  },

  changePassword(
    data: UserChangePassword,
    headers?: AxiosRequestHeaders,
  ): Promise<DictionaryResponse<[]>> {
    return client.put(UsersApi.ChangePassword, data, { headers });
  },

  completeOnboarding(): Promise<DictionaryResponse<User>> {
    return client.post(UsersApi.CompleteOnboarding);
  },
};
