import { HttpStatus } from '@/app/types/api/config/config';
import {
  ServiceGetters,
  ServiceMutations,
  ServiceState,
} from '@/app/types/store/service';

const state = (): ServiceState => ({
  serverStatus: null,
});

const mutations: ServiceMutations = {
  changeServerStatus(state, status) {
    state.serverStatus = status;
  },
};

const getters: ServiceGetters = {
  isServerStatusFetched: (state) => state.serverStatus !== null,
  isServerMaintaining: (state) =>
    state.serverStatus === HttpStatus.ServiceUnavailable,
};

export default {
  namespaced: true,

  state,
  getters,
  mutations,
};
