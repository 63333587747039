import accessRestricted from '@/lang/en/modules/accessRestricted';
import addFunds from '@/lang/en/modules/addFunds';
import balances from '@/lang/en/modules/balances';
import banking from '@/lang/en/modules/banking';
import business from '@/lang/en/modules/business';
import common from '@/lang/en/modules/common';
import contactUs from '@/lang/en/modules/contactUs';
import dashboard from '@/lang/en/modules/dashboard';
import documents from '@/lang/en/modules/documents';
import errors from '@/lang/en/modules/errors';
import maintenance from '@/lang/en/modules/maintenance';
import modals from '@/lang/en/modules/modals';
import notFound from '@/lang/en/modules/notFound';
import onboarding from '@/lang/en/modules/onboarding';
import payment from '@/lang/en/modules/payment';
import requests from '@/lang/en/modules/requests';
import settings from '@/lang/en/modules/settings';
import transactions from '@/lang/en/modules/transactions';
import withdraw from '@/lang/en/modules/withdraw';

export default {
  addFunds,
  banking,
  common,
  errors,
  modals,
  dashboard,
  payment,
  transactions,
  settings,
  notFound,
  business,
  withdraw,
  onboarding,
  balances,
  accessRestricted,
  requests,
  contactUs,
  maintenance,
  documents,
};
