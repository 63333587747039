import { createStore } from 'vuex';

import { RootState } from '@/app/types/store/store';

import balances from '@/store/modules/balances';
import dictionaries from '@/store/modules/dictionaries';
import organizations from '@/store/modules/organizations';
import service from '@/store/modules/service';
import users from '@/store/modules/users';
import verification from '@/store/modules/verification';

export default createStore<RootState>({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    users,
    dictionaries,
    verification,
    service,
    organizations,
    balances,
  },
});
