import { StoreAction } from '@/app/types/store/store';

import Store from '@/store';

import verification from '@/app/api/resources/verification';

export default class {
  checkVerification() {
    return verification.checkVerification().then(async ({ data }) => {
      await Store.dispatch(StoreAction.UpdateVerification, data.data);

      return Promise.resolve();
    });
  }
}
