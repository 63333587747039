import { BackendResponse } from '@/app/types/api/config/config';
import { ServiceEndpoint } from '@/app/types/api/resources/service';

import client from '@/app/api/client';

export default {
  serverStatus(): BackendResponse<['ok']> {
    return client.get(ServiceEndpoint.ServerStatus);
  },
};
