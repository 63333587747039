import {
  VerificationApprovalStatus,
  VerificationStateResult,
} from '@/app/types/api/resources/verification';
import {
  VerificationActions,
  VerificationGetters,
  VerificationMutations,
  VerificationState,
} from '@/app/types/store/verification';

const state = (): VerificationState => ({
  result: null,
  access_token: null,
  type: null,
  manual_approval_status: VerificationApprovalStatus.Waiting,
});

const mutations: VerificationMutations = {
  updateType(state, type) {
    state.type = type;
  },

  setStatus(state, status) {
    state.result = status;
  },

  setToken(state, token) {
    state.access_token = token;
  },

  setManualApprovalStatus(state, status) {
    state.manual_approval_status = status;
  },
};

const actions: VerificationActions = {
  updateVerification({ commit }, data) {
    commit('setStatus', data.result);
    commit('setToken', data.access_token);
    commit('updateType', data.type);
    commit('setManualApprovalStatus', data.manual_approval_status);
  },
};

const getters: VerificationGetters = {
  isOrganizationVerified: (state): boolean => {
    return (
      state.result === VerificationStateResult.Success &&
      state.manual_approval_status === VerificationApprovalStatus.Approved
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
