export default {
  standardServerError: 'An error occurred. Refresh page and try again',
  emailNotVerified: 'Your email address is not verified',
  integer: 'Value should be an integer and bigger than 0',
  maxNumber: 'Value can not be greater than {maxNum}',
  required: 'This field can not be empty',
  minSymbols: 'Minimum {symbol} symbols',
  maxSymbols: 'Maximum {symbol} symbols',
  integers: 'Only integers allowed',
  paymentFailed: 'Payment failed. Insufficient funds in your account',
  lowBalance: 'Top up balance and try again',
  passwordIsNotSame: "Password confirmation doesn't match password",
  passTemplate:
    'Password must be at least 8 characters with 1 uppercase letter, 1 lowercase and 1 number',
  urlTemplate: 'Enter a valid URL address',
  wrongCoinType: "Wrong coin type. You can't send transaction",
  requestWasDenied: 'We using another blockchain. Please change blockchain.',
  transactionDenied: 'Transaction was denied by user.',
  transactionRejected:
    'Transaction was rejected for unknown reason. Please try again later.',
  notEnoughFunds: 'Not enough funds',
  payment: {
    name: 'Name this payment so you know its purpose.',
    cryptocurrency: 'Choose the cryptocurrency you want to use.',
    selfPayment: 'Payments to yourself are not allowed',
  },
  fileRequired: 'File is required',
  fileType: 'This file type is not supported',
  maxSize: 'Maximum file size exceeded - {maxSize} MB',
  addressRequired: "Please enter recipient's address",
  topupMethodRequired: 'Choose add funds method',
  bankAccountRequired: 'Choose bank account',
  cryptoRequired: 'Choose cryptocurrency',
  currencyRequired: 'Choose currency',
  recipientRequired: "Please enter recipient's address",
  permissionsPending:
    'You currently have an active MetaMask window open. Please either close your active MetaMask window or continue using it. Thank you.',
  nonceTooLow: {
    message: 'Nonce is too low, try to change it manually. Follow the next',
    linkLabel: 'article.',
  },
  tronLinkLocked: 'TronLink locked. Please log in',
  tronLinkNotFound: 'To use this option install TronLink extension',
  tronAddressNotFound: 'Tron address was not found',
  contractNotFound: 'Contract not found',
  networkNotSupported:
    'Selected network {userNetwork} not supported. Please select {network}',
  moreThanFee: 'The amount must be more than the service fee',
  moreThanMinAmount: 'The amount must be more than minimum withdrawal amount',
  confirmYouRead: 'Please confirm that you have read and understand',
  insufficientBalance: 'Insufficient balance',
  minExchangeAmount: 'Minimum exchange amount is {minValue}',
  fundingAmount:
    'Document amount should be between {minAmount} and {maxAmount} {currencyCode}',
  serviceUnavailable:
    'Planned maintenance in progress. Some features unavailable.',
  dateRangeRequired: 'Set the date range.',
  accountRequired: 'Choose account',
  ibanNoSpaces: 'IBAN cannot contain spaces',
};
