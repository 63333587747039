import { Layout } from '@/app/types/app/common';
import { RouteName } from '@/app/types/app/routes';

export default [
  // blocked
  {
    path: '/access-restricted',
    name: RouteName.AccessRestricted,
    component: () => import('@/views/ServiceViews/ViewUserBlocked.vue'),
    meta: {
      layout: Layout.Empty,
      canLogout: true,
    },
  },
  // maintenance
  {
    name: RouteName.Maintenance,
    path: '/maintenance',
    component: () => import('@/views/ServiceViews/ViewMaintenance.vue'),
    meta: {
      layout: Layout.Empty,
    },
  },
  // 404
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  },
  {
    path: '/404',
    name: RouteName.NotFound,
    component: () => import('@/views/ServiceViews/ViewNotFound.vue'),
    meta: {
      layout: Layout.Empty,
      canLogout: true,
    },
  },
];
